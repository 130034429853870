
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import "../Styles/toolbar.css"

export default function ResponsiveNav() {
    const [visible, setVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        const handleScroll = () => {
            const offset = window.scrollY;

            setIsScrolled(offset > 20); // Change color after 50px of scrolling

        };

        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', checkScreenSize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const logo = <img alt="logo" src={require("../Assets/E.png")} height="55" />;

    const menuItems = [
        { label: 'Home', url: '/' },
        { label: 'About Us', url: '/about-us' },
        { label: 'Gallery', url: '/portfolio' },
        { label: 'Services', url: '/services' },
        { label: 'Contact Us', url: '/contact-us' },
    ];

    const renderMenuItems = () => (
        menuItems.map((item, index) => (
            <a key={index} className='menu-items p-component p-3' href={item.url}>
                {item.label}
            </a>
        ))
    );

    const mobileMenu = (
        <div className="dropdown-background">
            <Button icon="pi pi-bars" onClick={() => setVisible(true)} className="p-component p-button-text" />
        </div>
    );

    const desktopMenu = (
        <div className="flex align-items-center gap-5">
            {renderMenuItems()}
        </div>
    );

    const navStyle = {
        backgroundColor: isScrolled ? 'var(--blue)' : 'transparent',
        transition: 'background-color 0.3s ease',
    };

    return (
        <div className="fixed w-full z-5">
            <div className="flex justify-content-between align-items-center p-3" style={navStyle}>
                {logo}
                {isMobile ? mobileMenu : desktopMenu}
            </div>
            <Sidebar visible={visible} onHide={() => setVisible(false)} fullScreen>
                <h2 className="text-2xl heading font-bold mb-4">Menu</h2>
                <div className="flex flex-column">
                    {renderMenuItems()}
                </div>
            </Sidebar>
        </div>
    );
}