import React from "react"
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import "../Styles/contact.css"


class Contact extends React.Component {
    constructor() {
        super()
        this.state = {
            name: "",
            email: "",
            message: ""
        }
    }
    render() {
        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
        }
        return (
            <div>
                <div className="p-5 contact-us-background2">
                    <div className="text-5xl heading text-center font-bold my-8 text-white py-0">
                        CONTACT US
                    </div>
                </div>
                <div className="bg-blue contact-container">
                    <div className="contact-form">
                        <Card>
                            <div className="text-blue heading font-bold text-4xl mb-3">Message Us Here</div>
                            <div className="flex flex-column w-full">
                                <InputText className="mb-3 p-component" value={this.state.name} placeholder="Please enter your name" onChange={onChange} name="name" />
                                <InputText className="mb-3 p-component" value={this.state.email} placeholder="Please enter your email id" onChange={onChange} name="email" />
                                <InputTextarea className="mb-3 p-component" value={this.state.message} placeholder="Please enter your message" onChange={onChange} name="message" rows={5} cols={30} />
                                <Button className="max-w-10rem bg-blue p-component" label="Submit" />
                            </div>
                        </Card>
                    </div>
                    <div className="contact-info">
                        <div className="mb-3">
                            <div className="font-bold heading text-4xl text-white mb-3">EVITASA</div>
                            <div className="text-golden p-component text-xl">Plot No. 59, 60, 110 and 111</div>
                            <div className="text-golden p-component text-xl">Plot No. 109 and 60</div>
                            <div className="text-golden p-component text-xl">Kattedan Syno. 48</div>
                            <div className="text-golden p-component text-xl mb-3">Hyderabad, Telangana - 500077, India</div>
                        </div>
                        <div className="mb-5 p-component text-white text-xl">
                            For all project enquiries, please email <a className="cursor text-golden no-underline" rel="noopener noreferrer" target="_blank" href="mailto:info.evitasa@gmail.com">info.evitasa@gmail.com</a>
                        </div>
                        <div className="map-container">
                            <iframe
                                
                               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12128.942831661976!2d78.43208195782876!3d17.318094932725547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcbbd5dcba94dd3%3A0xad45f03ee72393d4!2sEVITASA!5e0!3m2!1sen!2sin!4v1722090153807!5m2!1sen!2sin" 

                                allowFullScreen={false}
                                aria-hidden="false"
                                tabIndex="0"
                                title="Google Map"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact