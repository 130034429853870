import React from 'react';
import '../Styles/collage_wardrobe.css'; // Make sure to import your CSS

// Import images
import other1 from "../Assets/Re-Sized for Website/Interior/_KMP6426.jpg"
import other7 from "../Assets/Re-Sized for Website/Interior/_KMP6509.jpg"
import other8 from "../Assets/Re-Sized for Website/Interior/_KMP6526.jpg"
import other9 from "../Assets/Re-Sized for Website/Interior/_KMP6533.jpg"
import other10 from "../Assets/Re-Sized for Website/Interior/_KMP6536.jpg"
// import other8 from "../Assets/Re-Sized for Website/Interior/_KMP6536.jpg"
import other11 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w2 (1).png"
import other12 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (1).jpeg"
import other13 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (2).png"
import other14 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (3).png"
import other15 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (4).png"
import other18 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (5).png"
import other19 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (6).png"

import other20 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (2).jpeg"
import other25 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (3).jpeg"
import other21 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (4).jpeg"
import other23 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (5).jpeg"
import other24 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (6).jpeg"

import other22 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (7).png"
import other26 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (8).png"
import other27 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (9).png"
import other28 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (10).png"
import other29 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (11).png"
import other30 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w4 (12).png"


import other16 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w1.jpeg"
import other17 from "../Assets/Re-Sized for Website/wardrobes-20240801T143232Z-001/wardrobes/w2.png"
 
const ImageCollage = () => {
    const images = [
        { src: other1, alt: 'Description 1' },
        // { src: other2, alt: 'Description 2' },
        // { src: other3, alt: 'Description 3' },
        // { src: other4, alt: 'Description 4' },
        // { src: other5, alt: 'Description 5' },
        // { src: other6, alt: 'Description 6' },
        { src: other7, alt: 'Description 7' },
        { src: other17, alt: 'Description 17' },
        { src: other8, alt: 'Description 8' },
        { src: other9, alt: 'Description 9' },
        { src: other10, alt: 'Description 10' },        // Add more images as needed
        { src: other16, alt: 'Description 7' },
        { src: other11, alt: 'Description 7' },
        { src: other13, alt: 'Description 7' },
        { src: other14, alt: 'Description 7' },
        { src: other15, alt: 'Description 7' },
        { src: other18, alt: 'Description 7' },
        { src: other12, alt: 'Description 7' },
        { src: other19, alt: 'Description 7' },

        { src: other20, alt: 'Description 7' },
        { src: other21, alt: 'Description 7' },
        { src: other23, alt: 'Description 7' },
        { src: other24, alt: 'Description 7' },
        { src: other26, alt: 'Description 7' },
        { src: other25, alt: 'Description 7' },

        { src: other22, alt: 'Description 7' },
        { src: other27, alt: 'Description 7' },
        { src: other28, alt: 'Description 7' },
        { src: other29, alt: 'Description 7' },
        { src: other30, alt: 'Description 7' },

    ];

    return (
        <section className="" style={{maxWidth:'90vw',margin:'auto'}}>
            <div className="collage-grid">
                {images.map((image, index) => (
                    <div 
                        key={index} 
                        className="collage-item"
                        style={{
                            gridColumn: image.gridColumn,
                            gridRow: image.gridRow,
                        }}
                    >
                        <img 
                            src={image.src}
                            alt={image.alt}
                            loading="lazy"
                            className="w-full h-full object-cover"
                        />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default ImageCollage;