import React from 'react';
import '../Styles/collage_kitchen.css'; // Make sure to import your CSS

// Import images
import other1 from "../Assets/Re-Sized for Website/Interior/_KMP6387.jpg"
import other2 from "../Assets/Re-Sized for Website/Interior/_KMP6431.jpg"
import other3 from "../Assets/Re-Sized for Website/Interior/_KMP6442.jpg"
import other4 from "../Assets/Re-Sized for Website/Interior/_KMP6456.jpg"
import other5 from "../Assets/Re-Sized for Website/Interior/_KMP6459.jpg"
import other6 from "../Assets/Re-Sized for Website/Interior/_KMP6465.jpg"
import other7 from "../Assets/Re-Sized for Website/Interior/_KMP6469.jpg"
import other8 from "../Assets/Re-Sized for Website/Interior/_KMP6356.jpg"
import other9 from "../Assets/Re-Sized for Website/Interior/_KMP6471.jpg"
import other10 from "../Assets/Re-Sized for Website/Interior/_KMP6471.jpg"
import other11 from "../Assets/Re-Sized for Website/kitchens-20240801T143214Z-001/kitchens/k1.jpeg"
import other12 from "../Assets/Re-Sized for Website/kitchens-20240801T143214Z-001/kitchens/k2.jpeg"
import other14 from "../Assets/Re-Sized for Website/kitchens-20240801T143214Z-001/kitchens/k3.png"
import other15 from "../Assets/Re-Sized for Website/kitchens-20240801T143214Z-001/kitchens/k4.jpeg"
import other16 from "../Assets/Re-Sized for Website/kitchens-20240801T143214Z-001/kitchens/k5.jpeg"
import other17 from "../Assets/Re-Sized for Website/kitchens-20240801T143214Z-001/kitchens/k6.jpeg"
import other18 from "../Assets/Re-Sized for Website/kitchens-20240801T143214Z-001/kitchens/k7.png"
// import other8 from "../Assets/Re-Sized for Website/Interior/_KMP6536.jpg"
 
const ImageCollage = () => {
    const images = [
        { src: other1, alt: 'Description 1' },
        { src: other10, alt: 'Description 10' },
        { src: other2, alt: 'Description 2' },
        { src: other3, alt: 'Description 3' },
        { src: other4, alt: 'Description 4' },
        { src: other5, alt: 'Description 5' },
        { src: other9, alt: 'Description 9' },
        // { src: other15, alt: 'Description 13' },
        { src: other6, alt: 'Description 6' },
        { src: other18, alt: 'Description 13' },
        { src: other7, alt: 'Description 7' },
        { src: other8, alt: 'Description 8' },
        { src: other14, alt: 'Description 13' },
        // { src: other12, alt: 'Description 12' },
        { src: other16, alt: 'Description 13' },
        { src: other17, alt: 'Description 13' },
        // { src: other11, alt: 'Description 11' },

       // Add more images as needed
    ];

    return (
        <section className="" style={{maxWidth:'90vw',margin:'auto'}}>
            <div className="collage-grid">
                {images.map((image, index) => (
                    <div 
                        key={index} 
                        className="collage-item"
                        style={{
                            gridColumn: image.gridColumn,
                            gridRow: image.gridRow,
                        }}
                    >
                        <img 
                            src={image.src}
                            alt={image.alt}
                            loading="lazy"
                            className="w-full h-full object-cover"
                        />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default ImageCollage;