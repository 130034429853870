import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Contact from './Pages/Contact';
import Portfolio from './Pages/Portfolio';
import Services from './Pages/Services';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Toolbar from "./Components/Toolbar"

class App extends React.Component {
    render () {
        return (
            <div>
                <Toolbar />
                <Routes>
                    <Route path="/contact-us" element={<Contact />} />
                    <Route path='/' exact element={<Home/>} />
                    <Route path='/about-us' element={<AboutUs />} />
                    <Route path='/services' element={<Services />} />
                    <Route path='/portfolio' element={<Portfolio />} />  
                </Routes>
            </div>
        )
    }
}

export default App;