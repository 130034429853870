import React from 'react';
import '../Styles/collage_other.css'; // Make sure to import your CSS

// Import images
import other1 from "../Assets/Re-Sized for Website/Interior/_KMP5725.jpg"
import other2 from "../Assets/Re-Sized for Website/Interior/_KMP6392.jpg"
import other3 from "../Assets/Re-Sized for Website/Interior/_KMP6406.jpg"
import other4 from "../Assets/Re-Sized for Website/Interior/_KMP6415.jpg"
import other5 from "../Assets/Re-Sized for Website/Interior/_KMP6085.jpg"
import other6 from "../Assets/Re-Sized for Website/Interior/_KMP6511.jpg"
import other7 from "../Assets/Re-Sized for Website/Interior/_KMP6515.jpg"
import other8 from "../Assets/Re-Sized for Website/Interior/_KMP6521.jpg"
import other9 from "../Assets/Re-Sized for Website/Interior/_KMP6528.jpg"
import other10 from "../Assets/Re-Sized for Website/Interior/_KMP6529.jpg"
// import other8 from "../Assets/Re-Sized for Website/Interior/_KMP6536.jpg"
import other11 from "../Assets/Re-Sized for Website/Interior/_KMP6477.jpg"
import other12 from "../Assets/Re-Sized for Website/Interior/_KMP6489.jpg"
import other13 from "../Assets/Re-Sized for Website/Interior/_KMP6481.jpg"

import other14 from "../Assets/Re-Sized for Website/other-20240801T143229Z-001/other/o (1).jpeg"
import other15 from "../Assets/Re-Sized for Website/other-20240801T143229Z-001/other/o (2).jpeg"
import other16 from "../Assets/Re-Sized for Website/other-20240801T143229Z-001/other/o (3).jpeg"
import other17 from "../Assets/Re-Sized for Website/other-20240801T143229Z-001/other/o (4).jpeg"
import other18 from "../Assets/Re-Sized for Website/other-20240801T143229Z-001/other/o (5).jpeg"
import other19 from "../Assets/Re-Sized for Website/other-20240801T143229Z-001/other/o (6).jpeg"
import other20 from "../Assets/Re-Sized for Website/other-20240801T143229Z-001/other/o (7).jpeg"
import other21 from "../Assets/Re-Sized for Website/other-20240801T143229Z-001/other/o (8).jpeg"
import other22 from "../Assets/Re-Sized for Website/other-20240801T143229Z-001/other/o (9).jpeg"
import other23 from "../Assets/Re-Sized for Website/other-20240801T143229Z-001/other/o (10).jpeg"


const ImageCollage = () => {
    const images = [
        { src: other1, alt: 'Description 1' },
        { src: other2, alt: 'Description 2' },
        { src: other3, alt: 'Description 3' },
        { src: other4, alt: 'Description 4' },
        { src: other5, alt: 'Description 5' },
        { src: other6, alt: 'Description 6' },
        { src: other7, alt: 'Description 7' },
        { src: other8, alt: 'Description 8' },
        { src: other9, alt: 'Description 9' },
        { src: other10, alt: 'Description 10' }, 
        { src: other11, alt: 'Description 11' },
        { src: other13, alt: 'Description 13' },
        { src: other12, alt: 'Description 12' },

        { src: other14, alt: 'Description 13' },
        { src: other15, alt: 'Description 14' },
        { src: other16, alt: 'Description 15' },
        { src: other17, alt: 'Description 16' },
        { src: other18, alt: 'Description 17' },
        { src: other19, alt: 'Description 18' },
        { src: other20, alt: 'Description 19' },
        { src: other21, alt: 'Description 20' },
        { src: other22, alt: 'Description 21' },
        { src: other23, alt: 'Description 22' },
        // Add more images as needed
    ];

    return (
        <section className="" style={{maxWidth:'90vw',margin:'auto'}}>
            <div className="collage-grid">
                {images.map((image, index) => (
                    <div 
                        key={index} 
                        className="collage-item"
                        style={{
                            gridColumn: image.gridColumn,
                            gridRow: image.gridRow,
                        }}
                    >
                        <img 
                            src={image.src}
                            alt={image.alt}
                            loading="lazy"
                            className="w-full h-full object-cover"
                        />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default ImageCollage;