import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./Styles/colorTheme.css"
import "./Styles/componentTheme.css"
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { IKContext } from 'imagekitio-react';

export const imagekitUrl = "https://ik.imagekit.io/q5h6vua0ve/"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <IKContext urlEndpoint={imagekitUrl}>
        <App />
      </IKContext>
    </BrowserRouter>
  </React.StrictMode>
);
