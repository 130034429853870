import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import "../Styles/home.css"
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

import loader from '../Assets/loader.mp4';
import home from '../Assets/EVITASA-1.mp4';


import homeImage1 from "../Assets/Re-Sized for Website/Interior/_KMP6550.jpg"
import homeImage2 from "../Assets/Re-Sized for Website/Interior/_KMP6550.jpg"
import homeImage3 from "../Assets/Re-Sized for Website/Interior/_KMP5707.jpg"
import homeImage4 from "../Assets/Re-Sized for Website/Interior/_KMP5725.jpg"
import homeImage6 from "../Assets/Re-Sized for Website/Factory/selection.jpg"
import homeImage7 from "../Assets/Re-Sized for Website/Factory/_KMP5973.jpg"
import homeImage8 from "../Assets/Re-Sized for Website/Interior/_KMP6415.jpg"

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoEnded: false
        };
        this.swiper = null;
    }

    handleVideoEnd = () => {
        this.setState({ videoEnded: true });
    };

    handleVideoPlay = () => {
        console.log("Video is playing");
    };

    handleVideoError = () => {
        console.error("Error playing video");
    };

    componentDidMount() {
        this.initializeSlider();
        window.addEventListener('resize', this.initializeSlider);
        this.initializeSwiper();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.initializeSlider);
        this.clearSlideInterval();
        if (this.swiper) {
            this.swiper.destroy();
        }
    }

    initializeSwiper = () => {
        this.swiper = new Swiper('.swiper-container', {
            slidesPerView: 1,
            spaceBetween: 20,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        });
    }
    clearSlideInterval = () => {
        if (this.slideInterval) {
            clearInterval(this.slideInterval);
        }
    }

    initializeSlider = () => {
        this.clearSlideInterval();

        if (window.innerWidth <= 480) {
            const slider = document.querySelector('.slider-container');
            const slides = document.querySelectorAll('.slide');
            const prevBtn = document.querySelector('.prev');
            const nextBtn = document.querySelector('.next');

            if (slider && slides.length && prevBtn && nextBtn) {
                let currentIndex = 0;

                const showSlide = (index) => {
                    slider.style.transform = `translateX(-${index * 100}%)`;
                };

                const nextSlide = () => {
                    currentIndex = (currentIndex + 1) % slides.length;
                    showSlide(currentIndex);
                };

                const prevSlide = () => {
                    currentIndex = (currentIndex - 1 + slides.length) % slides.length;
                    showSlide(currentIndex);
                };

                prevBtn.addEventListener('click', () => {
                    prevSlide();
                    this.resetSlideInterval();
                });

                nextBtn.addEventListener('click', () => {
                    nextSlide();
                    this.resetSlideInterval();
                });

                // Initialize the slider
                showSlide(currentIndex);

                // Set up auto-sliding
                this.slideInterval = setInterval(nextSlide, 3000); // Change slide every 3 seconds

                // Reset interval when user interacts with the slider
                slider.addEventListener('touchstart', this.resetSlideInterval);
                slider.addEventListener('mousedown', this.resetSlideInterval);
            }
        }
    }

    resetSlideInterval = () => {
        this.clearSlideInterval();
        this.slideInterval = setInterval(() => {
            const nextBtn = document.querySelector('.next');
            if (nextBtn) nextBtn.click();
        }, 3000);
    }



    render() {
        return (
            <div>

                <div className={`preloader ${this.state.videoEnded ? 'hidden' : 'hidden'}`}>

                    <div className="video-container">
                        <video
                            src={loader}
                            autoPlay={true}
                            loop={false}
                            muted={true}
                            className="video-element"
                            style={{ backgroundColor: '#8F897D' }}
                            onEnded={this.handleVideoEnd}
                            onPlay={this.handleVideoPlay}
                            onError={this.handleVideoError}
                        />
                    </div>
                </div>
                <div className="z-3 w-full absolute flex justify-content-end align-items-center h-screen card-container">
                    <Card className="max-w-30rem lg:mr-8 lg:mt-8 card-container">
                        <div className="text-blue text-xl sm:text-2xl lg:text-5xl heading font-bold mb-3">Discover Our Custom Made Products</div>
                        <div className="font-medium p-component text-justify text-0.25xl sm:text-0.5xl lg:text-xl text-black mb-3">
                            Here is a range of custom-made furniture and modular solutions, including kitchens and wardrobes, crafted in my factory. Each piece combines quality craftsmanship with innovative design, tailored to fit unique spaces and styles.
                        </div>
                        <Button className="text-white bg-blue hover:bg-golden hover:text-blue" onClick={() => window.location.href = "/portfolio"} label="OUR GALLERY" />
                    </Card>
                </div>
                <div className="w-full" style={{ lineHeight: 0 }}>
                    <video
                        src={home}
                        autoPlay={true}
                        loop={true}
                        controls={false}
                        muted={true}
                        width="100%"
                        height="100%"
                    />
                </div>

                <div className="bg-blue">
                    <div className="z-3 flex p-3  justify-content-end align-items-center card-phone">
                        <Card className="max-w-30rem bg-blue lg:mr-8 card-phone ">
                            <div className="text-blue text-xl sm:text-2xl lg:text-5xl heading font-bold mb-3">Discover Our Custom Made Products</div>
                            <div className="font-medium p-component text-justify text-0.25xl sm:text-0.5xl lg:text-xl text-black mb-3">
                                Here is a range of custom-made furniture and modular solutions, including kitchens and wardrobes, crafted in my factory. Each piece combines quality craftsmanship with innovative design, tailored to fit unique spaces and styles.
                            </div>
                            <Button className="text-white bg-blue text-sm hover:bg-golden hover:text-blue" onClick={() => window.location.href = "/portfolio"} label="OUR GALLERY" />
                        </Card>
                    </div>


                    <div className="px-3 bg-blue text-center no-phone">
                        <div className="tablet"></div>
                        <div className="text-3xl lg:text-4xl font-bold text-golden py-6 heading  mini">
                            A Closer Look at Our Production Facility
                        </div>
                        <div className="mx-0 sm:mx-4 lg:mx-0 flex flex-col sm:flex-row gap-3 justify-content-center mb-4 ">
                            <img src={homeImage3} alt="evitasa" className="production-image" />
                            <img src={homeImage2} alt="evitasa" className="production-image" />
                            <img src={homeImage4} alt="evitasa" className="production-image" />
                        </div>
                        <Button className="text-blue bg-golden hover:bg-white mb-5" label="OUR CATALOGUE"
                        onClick={() => window.location.href = "/portfolio"} 
                        />
                    </div>
                    {/* carousel */}
                    <div className="card-phone inspiration-collection px-3 py-6 bg-blue text-center">
                        <div className="text-3xl lg:text-4xl heading font-bold text-golden mb-4">A Closer Look at Our Production Facility</div>
                        <div className="mobile-slider block sm:hidden mb-4">
                            <div className="swiper-container">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide production-image-container">
                                        <img src={homeImage3} alt="evitasa" className="production-image" />
                                    </div>
                                    <div className="swiper-slide production-image-container">
                                        <img src={homeImage2} alt="evitasa" className="production-image" />
                                    </div>
                                    <div className="swiper-slide production-image-container">
                                        <img src={homeImage4} alt="evitasa" className="production-image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button className="text-blue bg-golden hover:bg-white mt-2" onClick={() => window.location.href = "/portfolio"} label="OUR CATALOGUE" />
                    </div>
                </div>


                {/* beautify */}
                <div className="beautify-space-container px-3 py-4 bg-golden flex flex-col md:flex-row justify-center gap-1 items-center">
                    <div className="max-w-full my-auto md:mx-5 lg:max-w-45rem">
                        <div className="heading font-bold text-3xl lg:text-4xl text-black mb-3 text-center lg:text-left">Welcome to Evitasa</div>
                        <div className="text-lg p-component lg:text-xl line-height-4 text-blue mb-4 md:mb-6 text-justify">
                            Where our crafting meets your comfort. Since August 1st, 2022, we have specialized in creating modular furniture that blends functionality with aesthetics. Our kitchens, wardrobes, plush sofas, beds, side tables, and consoles enhance your living spaces with style and comfort. We also offer wall paneling services for comprehensive interior transformations. With over 50 dedicated team members and a commitment to excellence, we ensure each project reflects our passion for quality and customer satisfaction. Experience furniture that enriches your life and transforms your home with us.
                        </div>
                        <div className="flex">
                            <Button label="SERVICES" className="p-component text-white bg-blue hover:bg-golden hover:text-blue" onClick={() => window.location.href = "/services"} />
                        </div>
                    </div>
                    <div className="w-full lg:w-auto lg:p-8 md:p-4 flex welcome-container">
                        <img src={homeImage1} alt="evitasa" className="welcome-image" />
                    </div>
                </div>

                {/* How it Works */}
                <div className="bg-blue px-3 py-6 text-center">
                    <div className="lg:text-4xl heading font-bold text-golden mb-6">How it works</div>

                    <div className="how-it-works-container mx-3 md:mx-4 flex flex-col md:flex-row gap-6 justify-content-center mb-8">
                        <div className="mb-4 md:mb-0 howItWorksContainer">
                            <img src={homeImage6} alt="evitasa" className="howIW-image" />
                            <div className="flex justify-content-center mb-3">
                                <div className="bg-black -mt-5 z-3 border-3 border-white border-circle w-3rem m-auto h-3rem flex align-items-center justify-content-center text-xl text-white font-bold">
                                    1
                                </div>
                            </div>
                            <div className="text-xl heading font-bold text-golden mb-2 text-center">
                                Selection
                            </div>
                            <div className="text-white p-component text-justify text-wrap">
                                Once the drawings are finalized, you can choose from a wide range of materials available in various colors and textures.
                            </div>
                        </div>
                        <div className="mb-4 md:mb-0 howItWorksContainer">
                            <img src={homeImage7} alt="evitasa" className="howIW-image" />
                            <div className="flex justify-content-center mb-3">
                                <div className="bg-black -mt-5 z-3 border-3 border-white border-circle w-3rem m-auto h-3rem flex align-items-center justify-content-center text-xl text-white font-bold">
                                    2
                                </div>
                            </div>
                            <div className="text-xl heading font-bold text-golden mb-2 text-center">
                                Ships From Warehouse
                            </div>
                            <div className="text-white p-component text-justify">
                                All production is completed in our factory, minimizing on-site work to prevent damage and dust. Equipped with advanced machinery, we ensure no compromise on quality or site hygiene.                        </div>
                        </div>
                        <div className="mb-4 md:mb-0 howItWorksContainer">
                            <img src={homeImage8} alt="evitasa" className="howIW-image" />
                            <div className="flex justify-content-center mb-3">
                                <div className="bg-black -mt-5 z-3 border-3 border-white border-circle w-3rem m-auto h-3rem flex align-items-center justify-content-center text-xl text-white font-bold">
                                    3
                                </div>
                            </div>
                            <div className="text-xl heading font-bold text-golden mb-2 text-center">
                                Installation
                            </div>
                            <div className="text-white p-component text-justify">
                                Once production is complete, we bubble wrap and safely transport all the finished materials. Our craftsmen then ensure smooth and efficient installation on site.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default Home;
