import React from "react";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CardCarousel from './Carousel3d';

import "../Styles/horizontalScroll.css"
// import home from '../Assets/HomeVideo.mp4';
import home from '../Assets/EVITASA-3.mp4';


import Other from "../Components/Collage_other"
import Kitchen from "../Components/Collage_kitchen"
import Wardrobe from "../Components/Collage_wardrobe"

gsap.registerPlugin(ScrollTrigger);

export default function Portfolio() {

    return (
        <div>

            <div className="w-full" style={{ lineHeight: 0 }}>
                <video
                    src={home}
                    autoPlay={true}
                    loop={true}
                    controls={false}
                    muted={true}
                    width="100%"
                    height="100%"
                />
            </div>
            <div className="px-3 py-6 bg-blue text-center h-full">

                <CardCarousel />

            </div>

            <div className="bg-golden align-items-center"  style={{ paddingBottom: '10vh' }}>
                
                <div id='cat-1' className="font-bold text-4xl pt-6 pb-3 md:text-4x1 lg:text-4xl text-black heading  text-center">Kitchen</div>
                    <Kitchen />
                <div id='cat-2' className="font-bold text-4xl pt-6 pb-3 md:text-4x1 lg:text-4xl text-black heading text-center">Wardrobe</div>
                    <Wardrobe />
                <div id='cat-3' className="font-bold text-4xl heading pt-6 pb-3 md:text-4x1 lg:text-4xl text-black  text-center">Others</div>
                    <Other />
            </div>
        </div>
    )

}