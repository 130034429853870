import React from "react";
import "../Styles/about.css"

import ImageAnimation from "../Components/Animation"
import aboutUs1 from "../Assets/Re-Sized for Website/Interior/_KMP6356.jpg"
import aboutUs2 from "../Assets/Re-Sized for Website/Interior/_KMP6415.jpg"
import aboutUs3 from "../Assets/Re-Sized for Website/Factory/_KMP6255.jpg"
import aboutUs4 from "../Assets/Re-Sized for Website/Factory/_KMP5837.jpg"
import aboutUs5 from "../Assets/Re-Sized for Website/Interior/_KMP5725.jpg"
import coreTeam1 from "../Assets/Re-Sized for Website/Factory/_KMP5753.jpg"
import coreTeam2 from "../Assets/Re-Sized for Website/Factory/_KMP5792.jpg"


class AboutUs extends React.Component {
    render() {
        return (
            <div>
                <div className="mb-5 bg-blue">
                    <ImageAnimation text={"About Us"} />
                </div>

                <div className="joiner"></div>
                {/* start */}
                <div className="mx-4 sm:mx-10 lg:mx-12  mb-7 scroll-animate-item">
                    <div className="flex flex-column-reverse md:flex-row items-center justify-content-center gap-3">
                        <div className="w-full px-2 lg:mr-8 md:mr-4 md:w-1/2 lg:w-4 md:mt-5 mr-10 scroll-animate-item">
                            <div className="text-blue heading font-bold mb-3 text-left sm:text-left text-2xl sm:text-3xl md:text-4xl"> Crafting Excellence: Our Journey in Modular Furniture Solutions</div>
                            <div className="text-xl p-component line-height-3 text-justify text-black mb-3">
                                Established on August 1st, 2022, our company embarked on a journey in the realm of furniture manufacturing, specializing in the creation of modular furniture solutions. Our focus lies particularly in crafting exquisite kitchens and wardrobes, offering tailored designs that seamlessly blend functionality with aesthetics.

                            </div>
                        </div>
                        <img src={aboutUs1} alt="evitasa" className="about-image" />
                    </div>
                </div>


                {/* special */}
                <div className="joiner"></div>

                <div className="mx-4 sm:mx-10 lg:mx-12 mb-7 scroll-animate-item">
                    <div className="flex flex-column md:flex-row justify-content-center gap-3 md:gap-6 lg:gap-8">
                        <img src={aboutUs2} alt="evitasa" className="about-image" />
                        <div className="w-full px-2 md:w-1/2 lg:w-4 md:mt-5">
                            <div className="text-blue heading font-bold mb-3 text-left sm:text-left text-2xl sm:text-3xl md:text-4xl">Transforming Spaces: From Signature Kitchens to Elegant Furniture</div>
                            <div className="text-xl p-component line-height-3 text-black mb-1 text-justify">
                                In addition to our signature kitchen and wardrobe designs, our diverse product line includes a wide array of loose furniture pieces that cater to various needs and preferences. From plush sofas to cozy beds, elegant side tables to functional consoles and center tables, our offerings are designed to enhance living spaces with both style and comfort. Our expertise also extends to wall paneling, providing comprehensive solutions for transforming interior spaces.

                            </div>
                        </div>
                    </div>
                </div>

                {/* Repeat the above two sections two more times */}
                {/* ... */}
                <div className="mx-4 sm:mx-10 lg:mx-12  mb-7 scroll-animate-item">
                    <div className="flex flex-column-reverse md:flex-row justify-content-center gap-3">
                        <div className="w-full px-2 lg:mr-8 md:mr-4 md:w-1/2 lg:w-4 md:mt-5 mr-10 scroll-animate-item">
                            <div className="text-blue heading font-bold mb-3 text-left sm:text-left text-2xl sm:text-3xl md:text-4xl"> Holistic Interior Solutions: From Vision to Reality</div>
                            <div className="text-xl p-component line-height-3 text-justify text-black mb-3">
                                As a full-service furniture manufacturer, we go beyond merely supplying products by offering a holistic approach to interior design. We provide comprehensive solutions tailored to the unique preferences and requirements of our clients. Our team of over 40 dedicated members is committed to bringing our clients' visions to life, ensuring every project is executed with precision and attention to detail.
                            </div>
                        </div>
                        <img src={aboutUs3} alt="evitasa" className="about-image" />
                    </div>
                </div>




                {/* special */}
                <div className="joiner"></div>

                <div className="mx-4 sm:mx-10 lg:mx-12 mb-7 scroll-animate-item">
                    <div className="flex flex-column md:flex-row justify-content-center gap-3 md:gap-6 lg:gap-8">
                        <img src={aboutUs4} alt="evitasa" className="about-image" />
                        <div className="w-full px-2 md:w-1/2 lg:w-4 md:mt-5">
                            <div className="text-blue heading font-bold mb-3 text-left sm:text-left text-2xl sm:text-3xl md:text-4xl">Commitment to Craftsmanship: Excellence in Every Detail</div>
                            <div className="text-xl p-component line-height-3 text-black mb-1 text-justify">
                                A hallmark of our company is our unwavering commitment to excellence in craftsmanship. We take pride in our meticulous attention to detail, ensuring that every piece we produce meets the highest standards of quality and durability. From the selection of premium materials to the finishing touches, our focus on craftsmanship excellence is evident in every aspect of our work.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mx-4 sm:mx-10 lg:mx-12  mb-7 scroll-animate-item">
                    <div className="flex flex-column-reverse md:flex-row justify-content-center gap-3">
                        <div className="w-full px-2 lg:mr-8 md:mr-4 md:w-1/2 lg:w-4 md:mt-5 mr-10 scroll-animate-item">
                            <div className="text-blue heading font-bold mb-3 text-left sm:text-left text-2xl sm:text-3xl md:text-4xl"> Customer-Centric Excellence: Curating Enriching Experiences</div>
                            <div className="text-xl p-component line-height-3 text-justify text-black mb-3">
                                At the core of our mission is a dedication to customer satisfaction. We believe that we are not merely selling furniture but curating experiences that enrich the lives of our clients. With a track record of over 30 completed projects in just 2 years, we have earned a reputation for reliability and excellence. Our commitment to exceeding customer expectations drives us to continually innovate and improve, ensuring that each interaction with our brand is exceptional.
                            </div>
                        </div>
                        <img src={aboutUs5} alt="evitasa" className="about-image" />
                    </div>
                </div>

                {/* end */}


                <div className="px-4 md:px-8 py-5 bg-blue text-center">
                    <div className="text-3xl heading lg:text-4xl font-bold text-golden mb-6">The Core of Our Team</div>
                    <div className="grid">
                        <div className="col-12 md:col-6 md:p-0 lg:p-4 p-5 md:mb-6">
                            <div className="flex flex-column md:flex-column lg:flex-column gap-3 m-2 md:m-0">
                                <div className="flex justify-content-center lg:pb-4">
                                    <img src={coreTeam1} alt="evitasa" className="team-image" />
                                </div>
                                <div className="text-left mt-3 md:mx-4 lg:mt-0 md:flex-1">
                                    <div className="text-golden heading text-xl md:text-2xl font-bold mb-1">Navin Lalwani</div>
                                    <div className="text-white heading mb-3">Founder</div>
                                    <div className="text-white p-component text-justify text-sm md:text-base">
                                        With over a decade entrenched in the furniture industry, I've guided the successful completion of 200+ projects. As the founder and managing director of Evitasa, my ethos is about more than transactions; it's about building lasting bonds grounded in trust.
                                        Evitasa's essence is in providing clients not just comfort through our products but also financial peace via competitive pricing without compromising on quality.
                                        My passion for this industry is rooted in its complexities and challenges. Instead of shying away, I see each obstacle as a chance for growth and innovation, propelling Evitasa forward. From navigating consumer shifts to embracing technological advances, the furniture industry demands adaptability. Leading Evitasa, I prioritise resilience and innovation, ensuring we exceed client expectations. As we forge ahead, I remain dedicated to Evitasa's core values of integrity, quality, and customer satisfaction. With each project, we set new benchmarks, pushing the boundaries of furniture manufacturing.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 md:col-6 md:p-0 mb-3 lg:p-4 p-5 md:mb-6">
                            <div className="flex flex-column md:flex-column lg:flex-column gap-3 m-2 md:m-0">
                                <div className="flex justify-content-center lg:pb-4">
                                    <img src={coreTeam2} alt="evitasa" className="team-image" />
                                </div>
                                <div className="text-left mt-3 md:mx-4 lg:mt-0 md:flex-1">
                                    <div className="text-golden heading text-xl md:text-2xl font-bold mb-1">Arbind kumar</div>
                                    <div className="text-white heading mb-3">Founder</div>
                                    <div className="text-white p-component text-justify text-sm md:text-base">
                                        As a founder of Evitasa, my journey in the modular kitchen industry over the past five years has been driven by an unwavering commitment to quality and customer satisfaction. We have built Evitasa on a foundation of trust, innovation, and ethical practices.
                                        At Evitasa, I leverage my keen understanding of customer needs to deliver solutions that are not only aesthetically pleasing but also highly functional and durable. My dedication to the craft and determination to exceed client expectations have been pivotal in establishing Evitasa as a trusted name in the industry.
                                        Through a hands-on approach and attention to detail, I ensure that every piece of furniture we create is a testament to our commitment to quality. My passion lies in turning customer visions into reality, ensuring they receive the best value without compromising on standards. As we continue to grow, I remain dedicated to exploring new possibilities in furniture design, always upholding our core values of integrity, quality, and customer satisfaction.
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default AboutUs