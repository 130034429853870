import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import '../Styles/animation.css';
//images for about us page
import Work1 from '../Assets/Re-Sized for Website/Factory/_KMP6188.jpg';
import Work2 from '../Assets/Re-Sized for Website/Interior/_KMP6550.jpg';
import Work3 from '../Assets/Re-Sized for Website/Factory/_KMP5848.jpg';

//images for services page
import Work4 from '../Assets/Re-Sized for Website/Factory/_KMP6040.jpg';
import Work5 from '../Assets/Re-Sized for Website/Factory/_KMP6168.jpg';
import Work6 from '../Assets/Re-Sized for Website/Factory/_KMP5868.jpg';

// add different images for servies page

const ImageAnimation = (props) => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [showText, setShowText] = useState(false);
  const images1=[Work1, Work2, Work3];
  const images2=[Work4, Work5, Work6];
  let images;
  if(props.text==="About Us"){
    images = images1;
  }
  else{
    images=images2;
  }

  useEffect(() => {
    if (imagesLoaded === images.length) {
      setTimeout(() => setShowText(true), 1000); // Delay text appearance by 1 second
    }
    else{

    }
  }, [imagesLoaded, images.length]);

  const handleImageLoad = () => {
    setImagesLoaded(prev => prev + 1);
  };

  return (
    <div className="image-container">
      {images.map((image, index) => (
        <motion.div
          key={index}
          className={`image-wrapper image-${index + 1}`}
          initial={{ x: 'var(--initial-x)', y: 'var(--initial-y)', opacity: 0 }}
          animate={{ x: 'var(--animate-x)', y: 'var(--animate-y)', opacity: 1 }}
          transition={{ duration: 1, delay: index * 0.2 }}
        >
          <img src={image} alt={`Evitasa ${index + 1}`} onLoad={handleImageLoad}/>
        </motion.div>
      ))}
      <AnimatePresence>
        {showText && (
          <motion.div 
            className="about-us-text heading"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
           
          >
            {props.text}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ImageAnimation;