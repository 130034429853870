import React, { Component } from 'react';
import { gsap } from 'gsap';
import $ from 'jquery';
import "../Styles/carousel3d.css";
import Swiper from 'swiper';
import carousalImage3 from "../Assets/Re-Sized for Website/Interior/_KMP5725.jpg"
import carousalImage2 from "../Assets/Re-Sized for Website/Interior/_KMP6528.jpg"
import carousalImage1 from "../Assets/Re-Sized for Website/Interior/_KMP6442.jpg"

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseX: 0,
      mouseY: 0,
      mouseZ: 0,
      addX: 0,
      mouseHover: false,
      firstTime: true
    };
    this.swiper = null;

    this.containerRef = React.createRef();
    this.carouselRef = React.createRef();
    this.fpsRef = React.createRef();
    this.fps_counter = {
      times: [],
      span: 20,
      tick: function () {
        this.times = this.times.concat(+new Date());
        if (this.times.length > this.span + 1) {
          this.times.shift();
          const seconds = (this.times[this.times.length - 1] - this.times[0]) / 1000;
          return Math.round(this.span / seconds);
        }
        return null;
      },
    };
    this.counter = Object.create(this.fps_counter);
  }

  componentDidMount() {

    this.initializeSlider();
    window.addEventListener('resize', this.initializeSlider);
    this.initializeSwiper();
    const $container = $(this.containerRef.current);
    const $carousel = $(this.carouselRef.current);
    const $item = $('.carouselItem');
    const itemLength = $item.length;
    const rY = 360 / itemLength;
    const radius = Math.round((250) / Math.tan(Math.PI / itemLength));

    gsap.set($container, { perspective: 600 });
    gsap.set($carousel, { z: radius });

    for (let i = 0; i < itemLength; i++) {
      const $currentItem = $item.eq(i);
      const $block = $currentItem.find('.carouselItemInner');
      if (this.state.firstTime) {
        gsap.set($currentItem, {
          rotationY: rY * i,
          z: radius,
          transformOrigin: `50% 50% ${-radius}px`
        });
        this.animateIn($currentItem, $block);
        this.setState({ firstTime: false });
      }
    }

    this.ticker = setInterval(() => {
      this.setState(prevState => {
        let newAddX = prevState.addX -prevState.mouseX * 0.7;
        gsap.to($carousel, {
          duration: 1,
          rotationY: newAddX,
          ease: 'quint.out'
        });
        return { addX: newAddX };
      });
    }, 1000 / 20);

    if (this.state.mouseHover) {
      window.addEventListener('mousemove', this.handleMouseMove);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.mouseHover !== this.state.mouseHover) {
      if (this.state.mouseHover) {
        window.addEventListener('mousemove', this.handleMouseMove);
      } else {
        window.removeEventListener('mousemove', this.handleMouseMove);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.initializeSlider);
    this.clearSlideInterval();
    if (this.swiper) {
      this.swiper.destroy();
  }
  
    window.removeEventListener('mousemove', this.handleMouseMove);
    clearInterval(this.ticker);
  }

  handleMouseMove = (event) => {
    const { innerWidth, innerHeight } = window;
    this.setState({
      mouseX: -(-(innerWidth * 0.5) + event.pageX) * 0.0025,
      mouseY: -(-(innerHeight * 0.5) + event.pageY) * 0.01,
      mouseZ: -(this.radius) - (Math.abs(-(innerHeight * 0.5) + event.pageY) - 200)
    });
  };

  handleMouseEnter = () => {
    this.setState({ mouseHover: true });
  };

  handleMouseLeave = () => {
    this.setState({ mouseHover: false });
  };

  animateIn = ($item, $block) => {
    const nrX = 360 * this.getRandomInt(2) * 0;
    const nrY = 360 * this.getRandomInt(2) * 0;
    const s = 1.5 + this.getRandomInt(10) * 0.1 * 0;
    const d = 0 - this.getRandomInt(8) * 0.1 * 0;

    gsap.set($item, { autoAlpha: 1, delay: d });
    gsap.set($block, { rotationY: nrY, rotationX: -nrX, autoAlpha: 0 });
    gsap.to($block, {
      duration: s,
      delay: d,
      rotationY: 0,
      rotationX: 0,
      z: 0,
      ease: 'expo.inOut'
    });
    gsap.to($block, {
      duration: s - 0.5,
      delay: d,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: 'expo.inOut'
    });
  };

  getRandomInt = (n) => Math.floor(Math.random() * n + 1);

  clearSlideInterval = () => {
    if (this.slideInterval) {
        clearInterval(this.slideInterval);
    }
}
initializeSwiper = () => {
  this.swiper = new Swiper('.swiper-container', {
      slidesPerView: 1,
      spaceBetween: 20,
      loop: true,
      navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      },
      pagination: {
          el: '.swiper-pagination',
          clickable: true,
      },
  });
}
initializeSlider = () => {
  this.clearSlideInterval();
  
  if (window.innerWidth <= 480) {
      const slider = document.querySelector('.slider-container');
      const slides = document.querySelectorAll('.slide');
      const prevBtn = document.querySelector('.prev');
      const nextBtn = document.querySelector('.next');

      if (slider && slides.length && prevBtn && nextBtn) {
          let currentIndex = 0;

          const showSlide = (index) => {
              slider.style.transform = `translateX(-${index * 100}%)`;
          };

          const nextSlide = () => {
              currentIndex = (currentIndex + 1) % slides.length;
              showSlide(currentIndex);
          };

          const prevSlide = () => {
              currentIndex = (currentIndex - 1 + slides.length) % slides.length;
              showSlide(currentIndex);
          };

          prevBtn.addEventListener('click', () => {
              prevSlide();
              this.resetSlideInterval();
          });

          nextBtn.addEventListener('click', () => {
              nextSlide();
              this.resetSlideInterval();
          });

          // Initialize the slider
          showSlide(currentIndex);

          // Set up auto-sliding
          this.slideInterval = setInterval(nextSlide, 3000); // Change slide every 3 seconds

          // Reset interval when user interacts with the slider
          slider.addEventListener('touchstart', this.resetSlideInterval);
          slider.addEventListener('mousedown', this.resetSlideInterval);
      }
  }
}

resetSlideInterval = () => {
  this.clearSlideInterval();
  this.slideInterval = setInterval(() => {
      const nextBtn = document.querySelector('.next');
      if (nextBtn) nextBtn.click();
  }, 3000);
}
scrollCategoryIntoView=(categ)=> {
  const element = document.getElementById(categ);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', alignToTop: true });
      }
  }
  
render() {
  const carousalImage=[
    carousalImage3,carousalImage2,carousalImage1
  ]
  const images=[
    //store in reverse order
        {
          src:{carousalImage},
          name:'Others',
          linkTo:'cat-3'//used to scroll to that elem onClick
        },
        {
          src:{carousalImage},
          name:'Wardrobe',
          linkTo:'cat-2'
        },
        {
          src:{carousalImage},
          // src:'https://images.ctfassets.net/5de70he6op10/50QfzqZtznzKmndekG3tdB/63cda335fc12c0b53d7096a5560e54f8/529573435-furniture_gateway_ls_07.jpg',
          name:'Kitchen',
          linkTo:'cat-1'
        }
  ]
    return (
      <>
        <div className="font-bold justify-content-center heading text-4xl text-golden mb-4">Inspiration Collection</div>

        <div
          id="contentContainer"
          className="trans3d no-phone no-tab"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          ref={this.containerRef}
        >
          {//for laptops
          }
          <section id="carouselContainer" className="trans3d no-tab" ref={this.carouselRef}>
            {[...Array(24)].map((_, i) => (
              <>
              <div key={i} id={`item${i + 1}`} className="flex carouselItem trans3d no-tab">
                {(i % 6 >= 3) && (
                  <>
                    <img
                      src={carousalImage[(i%6)%3]}
                      // src={carousalImage1}
                      className="carouselItemInner trans3d"
                      alt={`Collection ${i + 1}`}
                      onClick={() => {
                        const element = document.getElementById(images[(i%6)%3].linkTo);
                            if (element) {
                            element.scrollIntoView({ behavior: 'smooth', alignToTop: true });
                            }
                    }}
                    />
                    <div key={i} id={`1`} className="carouselItemInner2 heading trans3d font-bold text-2xl text-golden">{images[(i%6)%3].name}</div>
                    </>
                  )}
                      
              </div>
              </>
            ))}
          </section>
        </div>
        {
          //for phones
        }
                        <div className="card-phone inspiration-collection px-3 pt-0 bg-blue text-center">
                            <div className="mobile-slider block sm:hidden mb-0">
                                <div className="swiper-container">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                          {
                                            //replace with 'images[i].src'
                                            //and change the 'linksTo' as needed
                                          }
                                            <img src={carousalImage[2]} className="swiper-slide" loading="lazy" alt="evitasa"
                                            onClick={()=>{
                                                  this.scrollCategoryIntoView(images[2].linkTo);
                                            }}/>
                                            <div className="font-bold mt-4 heading justify-content-center text-2xl text-golden mb-4 ">
                                            {images[2].name}</div>
                                        </div>
                                        <div className="swiper-slide">
                                        <img src={carousalImage[1]} className="swiper-slide" loading="lazy" alt="evitasa"
                                            onClick={()=>{
                                                  this.scrollCategoryIntoView(images[1].linkTo);
                                            }}/>
                                            <div className="font-bold heading mt-4 justify-content-center text-2xl text-golden mb-4 ">
                                            {images[1].name}</div>

                                        </div>
                                        <div className="swiper-slide">
                                        <img src={carousalImage[0]} className="swiper-slide" loading="lazy" alt="evitasa"
                                            onClick={()=>{
                                                  this.scrollCategoryIntoView(images[0].linkTo);
                                            }}/>
                                            <div className="font-bold heading mt-4 justify-content-center text-2xl text-golden mb-4 ">
                                              {images[0].name}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="slider-nav flex justify-between items-center">
                                </div>
                            </div>
                        </div>


                {
                  //for tablet
                }
                <div className="px-3 tabs bg-blue text-center">
                    <div className="mx-2 tabs sm:mx-4 lg:mx-8 flex flex-col gap-6 justify-content-center mb-0">
                        <div className="w-full sm:w-auto">
                        {
                         //replace with 'images[i].src'
                         //and change the 'linksTo' as needed
                        }
                            <img src={carousalImage[2]} loading="lazy" className="w-full h-auto" alt="evitasa" onClick={()=>{
                                                 this.scrollCategoryIntoView(images[2].linkTo);                                                    
                                            }}/>
                                            <div className="font-bold mt-4 justify-content-center text-2xl text-golden mb-4 ">
                                            {images[2].name}</div>
                        </div>
                        <div className="w-full sm:w-auto">
                            <img src={carousalImage[1]} loading="lazy" className="w-full h-auto" alt="evitasa" onClick={()=>{
                                                  this.scrollCategoryIntoView(images[1].linkTo);                                                    
                                            }}/>
                                            <div className="font-bold mt-4 justify-content-center text-2xl text-golden mb-4 ">
                                            {images[1].name}</div>

                        </div>
                        <div className="w-full sm:w-auto">
                            <img src={carousalImage[0]} loading="lazy" className="w-full h-auto" alt="evitasa"  onClick={()=>{
                                                  this.scrollCategoryIntoView(images[0].linkTo);                                                
                                            }}/>
                                            <div className="font-bold mt-4 justify-content-center text-2xl text-golden mb-4 ">
                                            {images[0].name}</div>
                        </div>
                    </div>
                </div>
      </>
    );
  }
}

export default Carousel;
