import React from "react"
import "../Styles/about.css"
import ImageAnimation from "../Components/Animation"
import servicesImage1 from "../Assets/Re-Sized for Website/Factory/_KMP5901.jpg"
import servicesImage2 from "../Assets/Re-Sized for Website/Interior/_KMP6533.jpg"
import servicesImage3 from "../Assets/Re-Sized for Website/Factory/_KMP6271.jpg"

class Services extends React.Component {

    render() {
        return (
            <div>
                <div className="mb-5 bg-blue">
                    <ImageAnimation text={"Our Services"} />
                </div>

                <div className="joiner"></div>
                {/* start */}
                <div className="mx-4 sm:mx-10 lg:mx-12  mb-7 scroll-animate-item">
                    <div className="flex flex-column-reverse md:flex-row justify-content-center gap-3">
                        <div className="w-full px-2 lg:mr-8 md:mr-4 md:w-1/2 lg:w-4 md:mt-5 mr-10 scroll-animate-item">
                            <div className="text-xl p-component line-height-3 text-justify text-black mb-3">
                                We provide comprehensive solutions tailored to your preferences. Our team create bespoke
                                furniture pieces, seamlessly integrated into your space. We use premium materials like BWP
                                710 grade plywood and hardware from trusted brands such as Hafele, Salice, Blum, and
                                Grass to ensure durability.
                                We specialise in customization, allowing you to personalise size, shape, colour, and finish to suit your space.We source only the finest materials, including laminates from Merino, Century, and Green, along with fabrics from Warwick and Italian leather. With our commitment to quality and craftsmanship, we're your trusted partner for exceptional furniture solutions.

                            </div>
                        </div>
                        <img src={servicesImage1} alt="evitasa" className="about-image" />
                    </div>
                </div>
                {/* special */}
                <div className="mx-4 sm:mx-10 lg:mx-12  mb-7 scroll-animate-item">
                    <div className="flex flex-column-reverse md:flex-row justify-content-center gap-3">
                        <img src={servicesImage2} alt="evitasa" className="lg:mr-8 about-image" />

                        <div className="w-full px-2  md:mr-4 md:w-1/2 lg:w-4 md:mt-5 mr-10 scroll-animate-item">
                            <div className="text-xl p-component line-height-3 text-justify text-black mb-3">
                                We specialise in creating modular kitchens and wardrobes, offering an extensive range of customization options to suit your unique preferences and lifestyle. Our commitment to excellence means that every single inch of our products receives meticulous attention to detail. We pride ourselves on delivering a sleek and timeless look, ensuring that our designs not only enhance the functionality of your space but also provide a sophisticated and stylish aesthetic. Whether you seek modern minimalism or classic elegance, we cater to your individual needs, creating spaces that are both beautiful and practical.

                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-4 sm:mx-10 lg:mx-12  mb-7 scroll-animate-item">
                    <div className="flex flex-column-reverse md:flex-row justify-content-center gap-3">

                        <div className="w-full px-2 lg:mr-8 md:mr-4 md:w-1/2 lg:w-4 md:mt-5 mr-10 scroll-animate-item">
                            <div className="text-xl p-component line-height-3 text-justify text-black mb-3">
                                Our facility is equipped with high-quality machinery and advanced technology to ensure precision and efficiency in our production process. This includes a Hotpress for pressing laminate onto plywood, a Panel Saw for accurate cutting, and an Edge Binding machine for seamlessly finishing corners. We also utilise Multi Boarding equipment for creating small holes necessary for minifix fittings and other hardware installations. These state-of-the-art machines, including Hotpress, Panelsaw, Edge Binding, and Drilltec, enable us to deliver superior craftsmanship and impeccable quality in every project.

                            </div>
                        </div>
                        <img src={servicesImage3} alt="evitasa" className="about-image" />

                    </div>
                </div>

                {/* 
                <div className="px-4 md:px-8 py-5 bg-blue text-center">
                    <div className="text-3xl lg:text-4xl font-bold text-golden mb-3">The Core of Our Team</div>


                    <div className="text-white text-lg md:text-xl mb-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                    <div className="grid">
                        <div className="col-12 md:col-6 md:p-0 lg:p-4 p-5 md:mb-6">
                        <div className="flex flex-column md:flex-column lg:flex-row gap-3 m-2 md:m-0">
                                <div className="flex justify-content-center">
                                    <img src={Team1} alt="evitasa" className="team-image" />
                                </div>
                                <div className="text-left mt-3 md:mx-4 lg:mt-0 md:flex-1">
                                    <div className="text-golden text-xl md:text-2xl font-bold mb-1">Aditya Shukla</div>
                                    <div className="text-white mb-3">CEO</div>
                                    <div className="text-white text-sm md:text-base">
                                        Empowering dreams, one loan at a time. Evitasa brings finance to the common man with simplicity and accessibility.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 md:col-6 md:p-0 mb-6 lg:p-4 p-5 md:mb-6">
                        <div className="flex flex-column md:flex-column lg:flex-row gap-3 m-2 md:m-0">
                                <div className="flex justify-content-center">
                                    <img src={Team1} alt="evitasa" className="team-image" />
                                </div>
                                <div className="text-left mt-3 md:mx-4 lg:mt-0 md:flex-1">
                                    <div className="text-golden text-xl md:text-2xl font-bold mb-1">Jane Doe</div>
                                    <div className="text-white mb-3">CTO</div>
                                    <div className="text-white text-sm md:text-base">
                                        Driving innovation and technological advancement to make finance accessible to all.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 md:col-6 md:p-0 lg:p-4 p-5 md:mb-6">
                        <div className="flex flex-column md:flex-column lg:flex-row gap-3 m-2 md:m-0">
                                <div className="flex justify-content-center">
                                    <img src={Team1} alt="evitasa" className="team-image" />
                                </div>
                                <div className="text-left mt-3 md:mx-4 lg:mt-0 md:flex-1">
                                    <div className="text-golden text-xl md:text-2xl font-bold mb-1">Aditya Shukla</div>
                                    <div className="text-white mb-3">CEO</div>
                                    <div className="text-white text-sm md:text-base">
                                        Empowering dreams, one loan at a time. Evitasa brings finance to the common man with simplicity and accessibility.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 md:col-6 md:p-0 lg:p-4 p-5 md:mb-6">
                        <div className="flex flex-column md:flex-column lg:flex-row gap-3 m-2 md:m-0">
                                <div className="flex justify-content-center">
                                    <img src={Team1} alt="evitasa" className="team-image" />
                                </div>
                                <div className="text-left mt-3 md:mx-4 lg:mt-0 md:flex-1">
                                    <div className="text-golden text-xl md:text-2xl font-bold mb-1">Jane Doe</div>
                                    <div className="text-white mb-3">CTO</div>
                                    <div className="text-white text-sm md:text-base">
                                        Driving innovation and technological advancement to make finance accessible to all.
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="bg-blue pb-6 our-team-background-container">
                    <div className="text-3xl lg:text-4xl font-bold text-golden mb-3 top-10">Our Team</div>
                <img src={AboutImage} alt="Our Team" className="our-team-background"/>
                </div> */}
            </div>
        )
    }
}

export default Services